<template>

  <div id="aussteller" class="firmenliste">
    <ul>
      <li v-for="firma in aussteller" :key="firma.id"
          @click="goto(firma.Webseite)"
      >

        <div class="logo">
          <img :src="firma.Logo" :alt="firma.Name" :title="firma.Name">
        </div>
        <div class="overlay">{{ firma.Firma }}</div>


      </li>
    </ul>
  </div>
</template>

<script>


export default {
  name: 'lpausstellerresult',
  components: {},
  props: {
    aussteller: Array,
    gesuchterberuf: String
  },
  data() {
    return {}
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },

  },

  methods: {
    goto(link) {
      window.open(link, '_blank')
    }
  },
}
</script>

<style scoped lang="scss">
.firmenliste {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    margin-top: 0px;
    margin-bottom: 0px;

    @media screen and (min-width: 480px) {
      justify-content: space-between;
    }

    li {
      width: 100%;
      width: calc(50% - 10px);
      background-color: white;
      position: relative;
      margin-bottom: 0px;
      cursor: pointer;

      @media screen and (min-width: 480px) {
        width: calc(50% - 10px);
      }
      @media screen and (min-width: 768px) {
        width: calc(33.33333333% - 10px);
      }
      @media screen and (min-width: 1024px) {
        width: calc(20% - 10px);
      }

      &:hover {
        .overlay {
          max-height: 1000px;
        }
      }

      .logo {
        padding: 20px;

        img {
          width: 100%;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 20px;
        background-color: var(--color-second);
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        max-height: 0;
        overflow: hidden;
        hyphens: auto;
        white-space: normal;

        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;

        @media screen and (min-width: 480px) {
          font-size: 22px;
          line-height: 1.3;
        }
        @media screen and (min-width: 1024px) {
          font-size: 20px;
          line-height: 1.2;
        }
      }
    }
  }
}

.newhallenbox {
  padding: 0 20px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      flex-basis: auto;
      flex-grow: 1;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      a {
        text-align: left;
        font: normal normal 500 24px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }
}
</style>
