<template>
  <div class="home">
    <Header :started="false"/>

    <div class="header-banner">
      <VueSlickCarousel :arrows="false" :dots="true" :slidesToShow="1" :autoplay="true"
                        :infinite="true" :speed="500"
                        class="slider">
        <div class="img-container">
          <img src="../assets/header03.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">Meine Arbeit. Mein Zuhause</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>

        <div class="img-container">
          <img src="../assets/header02.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">In der Heimat durchstarten</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>

        <div class="img-container">
          <img src="../assets/header01.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">Ihre Heimat. Ihre Zukunft</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <div class="ausrichtung">
      <div class="page-title">
        <div class="top">
          <div class="title">Kommen und Bleiben</div>
          <div class="img"><img src="../assets/meine-region.png" alt="Meine Region"/></div>
        </div>
        <div class="bottom">
          <img src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>

      <div class="box starttext" style="background-color: transparent">
        <div style="width: 100%;box-sizing: border-box;margin-bottom: 20px;">{{ this.$root.Einleitungtitle }}</div>
        <div v-html="this.$root.Einleitung" class="custom"></div>

      </div>


      <div
        class="image pointer">
        <div>
          <video :src="'./videos/begleitvideo_meissen__v3_hd.mp4'"
                 controls
                 width="100%"
                 :poster="'./videos/begleitvideo_meissen__v3_4k.png'"
                 style="max-width: 100%;"/>
        </div>

      </div>


      <VueSlickCarousel :arrows="true" :dots="true"  :autoplay="false"
                          :infinite="true" :speed="500"
                        class="slider slider-boxes">


        <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/51.jpg'">
          </div>
        </div>
        <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/59.jpg'">
          </div>
        </div>

        <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/64.jpg'">
          </div>
        </div>
        <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/78.3.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/105.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/119.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/122.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/125.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/137.2.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/138.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/141.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/147.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/155.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/159.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/169.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/171.jpg'">
          </div>
        </div>
  <div class="image pointer">
          <div>
            <img :src="'./images/lp2024/179.jpg'">
          </div>
        </div>

      </VueSlickCarousel>

      <div class="document-container">
        <div class="left" @click="openLKInfo2023" style="cursor: pointer">
          <img src="../assets/PDF-Deckblatt.png"/>
          <div class="content">
            <div>
              <div class="top">PDF</div>
              <div class="msg">Der Landkreis Meißen Städte und Gemeinden</div>
            </div>

          </div>
        </div>
        <div class="right" @click="openLKDaten" style="cursor: pointer" v-if="false">
          <img src="../assets/PDF-Deckblatt.png"/>
          <div class="content">
            <div>
              <div class="top">PDF</div>
              <div class="msg">Der Landkreis Meißen <br>Städte und Gemeinden<br>Daten & Fakten</div>
            </div>

          </div>
        </div>
      </div>

    </div>


    <div class="ausrichtung">

      <div>


        <div id="aussteller" class="firmenliste">
          <h3 style="text-align: center">Aussteller</h3>
          <ul>
            <li v-for="firma in aussteller" :key="firma.id"

            >
              <div class="logo">
                <img :src="firma.Logo" :alt="firma.Name" :title="firma.Name">
              </div>
              <div class="overlay">{{ firma.Firma }}</div>

              <!--
              <div v-if="chatonline(firma)">
                Live Chat
              </div>
              -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import filter from 'lodash/filter'
import Header from '../components/header/header'
import Punktkarte from '../components/Punktkarte'
import Punktkarteleer from '../components/Punktkarteleer'

import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
// optional style for arrows & dots
import 'slick-vuejs/dist/slick-vuejs-theme.css'
import Ausstellerresultliste from "@/components/austellerresultliste.vue";

export default {
  name: 'Home',
  components: {Ausstellerresultliste, Punktkarte, Punktkarteleer, Header, VueSlickCarousel},
  data() {
    return {
      showmap: false,
      ausstellersearch: '',
      berufsbezeichnungsearch: '',
      overlayVideo: null,
      publicPath: process.env.BASE_URL,
      wwwPath: process.env.VUE_APP_API_ENDPOINT,
      hallesearch: null,
      slickbreakpoints: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ],
    }
  },
  computed: {
    aussteller() {
      let self = this

      return filter(this.$root.teilnehmer, function (tn) {
        if (self.ausstellersearch != '') {
          return tn.Firma.toLowerCase().includes(self.ausstellersearch.toLowerCase())
            && (tn.Halle === self.hallesearch || self.hallesearch == null)
        } else if (self.berufsbezeichnungsearch != '') {
          return tn.berufe.find(function (beruf) {
            return beruf.Beruf.toLowerCase().includes(self.berufsbezeichnungsearch.toLowerCase())
          })
        } else {
          return tn.Firma.toLowerCase()
            && (tn.Halle === self.hallesearch || self.hallesearch == null)
        }

      })
    },
    countAussteller(id) {
      return this.$root.teilnehmer.filter(
        tn => parseInt(tn.Halle) === parseInt(id),
      ).length
    },
    hallen() {
      return this.$root.hallen
    },

    berufsbezeichnungen() {
      // get all Berufe from all Teilnehmer
      let berufsbezeichnungen = []
      this.$root.teilnehmer.forEach(function (tn) {
        tn.berufe.forEach(function (beruf) {
          // add if not allready in object
          if (!berufsbezeichnungen.find(function (b) {
            return b.Beruf === beruf.Beruf
          })) {
            berufsbezeichnungen.push(beruf)
          }
        })
      })

      return berufsbezeichnungen
    },
    berufsbezeichnungenSearch() {
      let self = this
      return filter(this.berufsbezeichnungen, function (beruf) {
        return beruf.Beruf.toLowerCase().includes(self.berufsbezeichnungsearch.toLowerCase())
      })

    }
  },
  methods: {
    chatonline(firm) {
      let firma = firm

      // console.log(this.$parent.$refs.chatwidget.firms)

      let firms = JSON.parse(JSON.stringify(this.$parent.$refs.chatwidget.firms))
      // console.log(firms)
      let valObj = firms.filter(function (elem) {
        return (parseInt(elem.firmaid) === parseInt(firma.id))
      })

      return valObj.length > 0

    },
    hallcolor(firm) {
      let firma = firm

      let hallen = JSON.parse(JSON.stringify(this.$root.hallen))

      let valObj = hallen.filter(function (halle) {

        return (parseInt(halle.id) === parseInt(firma.Halle))
      })
      if (valObj[0] && valObj[0].mcolor)
        return valObj[0].mcolor

    },
    openLKInfo2023() {
      window.open(this.wwwPath + '/uploads/startseite/2023-2024/Staedte-und-Gemeinden-FKM_2023.pdf', '_blank')
    },
    openLKInfo() {
      window.open(this.wwwPath + '/uploads/startseite/Landkreisinformationen_Meissen.pdf', '_blank')
    },
    openLKAp() {
      window.open(this.wwwPath + '/uploads/startseite/Ansprechpartner_im_LRA_Meissen_01.pdf', '_blank')
    },
    openLKDaten() {
      window.open(this.wwwPath + '/uploads/Flashstick_FKM_2022.pdf', '_blank')
    },
    switchTo3d() {
      this.$root.displaymode = '3d'
    },
    switchTo2d() {
      this.$root.displaymode = '2d'
    },
    goto: function (firmenid, hallenid) {
      if (this.$root.displaymode == '2d') {
        this.$router.push({name: 'Stand2d', params: {id: hallenid, stand: firmenid}})
      } else {
        this.$router.push({name: 'Hall', params: {id: hallenid, stand: firmenid}})
      }
    },
    activatemap() {
      this.showmap = true
      //karte neu positionieren?
    }
  }
}
</script>
<style lang="scss">
.header-banner {
  .slider {
    .img-container {
      position: relative;

      @media (max-width: 767px) {
        height: 80vw;
      }

      .overlay-container {
        position: absolute;
        bottom: 40%;
        width: 100%;
        z-index: 9;

        @media (max-width: 767px) {
          display: none;
        }

        .overlay-container-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .overlay-title {
            display: inline-block;
            background-color: rgba(10, 62, 84, 0.85);
            padding: 20px;
            line-height: 1;

            text-align: left;
            font-weight: normal;
            font-size: clamp(2.5rem, calc(2.0934rem + 1.8072vw), 3.25rem);;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            margin-left: 20px;
          }

          .overlay-title-sub {
            display: inline-block;
            background-color: white;
            padding: 10px;
            line-height: 1;

            text-align: left;
            font-family: "PT Sans", sans-serif;
            font-weight: 500;
            font-size: 24px;
            letter-spacing: 0;
            color: #0c4760;
            opacity: 1;
            margin-top: -10px;
            margin-left: 230px;
          }
        }
      }

      .overlay-welle {
        position: absolute;
        bottom: 23%;
        z-index: 8;

        @media (max-width: 767px) {
          bottom: 17%;
        }
      }
    }

    img {
      border: none;

      @media (max-width: 767px) {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

.slider {
  margin: 0;

  .slick-dots {
    bottom: 21px;

    li {
      margin: 0;

      &.slick-active {
        button::before {
          color: rgba(10, 62, 84, 0.85);
        }
      }

      button::before {
        font-size: 13px;
        opacity: 1;
        color: white;
      }
    }
  }

  .slick-prev, .slick-next {
    top: 40%;
  }

  .slick-prev {
    left: -30px;

    @media screen and (min-width: 768px) {
      left: -50px;
    }
  }

  .slick-next {
    right: -20px;

    @media screen and (min-width: 768px) {
      right: -30px;
    }
  }

  .slick-prev::before, .slick-next::before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #000000;
    content: '';
    background: url('../assets/icons/pfeil-links.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 30px;
    height: 30px;
    display: inline-block;

    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;

    }
  }

  .slick-next::before {
    transform: rotateY(180deg);
  }

  img {
    width: 100%;
    height: auto;
    border: 1px solid #00A650;
    border-bottom: 0;

  }

  .playicon {
    width: 55px;
    height: 55px;

    position: absolute;
    transition: color 250ms;
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: red;
    }

    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 18%;
  }

  .image {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    .text {
      border: 1px solid #00A650;
      box-sizing: border-box;
      width: 100%;
      text-transform: none;
      padding: 10px 10px;
      margin-left: 0px;
      min-height: 70px;

      text-align: left;
      font-family: "PT Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0px;
      color: #0c4760;
      opacity: 1;
      //height: 81px;
    }
  }

  /*
   overlay text
    .image {
      position: relative;
      box-sizing: border-box;
      cursor: pointer;

      .text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        background-color: rgba(68, 68, 68, 0.85);
        padding: 10px 20px;
        margin-left: 10px;
      }
    }
  */
}

.slider-boxes {
  margin-top: 40px;
  max-width: 80%;
  margin: 40px auto;

  @media screen and (min-width: 1024px) {
    max-width: calc(100% - 20px);
  }

  .slick-slide {
    padding: 10px !important;

  }


}
</style>
<style scoped lang="scss">

.page-title {
  display: flex;
  flex-direction: column;
  padding: 60px 0px 40px 0px;

  @media screen and (min-width: 768px) {
    padding: 80px 20px 40px 20px;
  }

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    .title {
      text-align: left;
      margin-right: 20px;
      width: 100%;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
      }
    }

    .img {
      width: 183px;

      img {
        width: 100%;
      }
    }
  }

  .bottom {
    margin-top: 10px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
    }

    img {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }
}

.starttext {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;


}

.suche-neu {
  width: 100%;
  background: transparent linear-gradient(243deg, #00AAB7 0%, #009951 100%) 0% 0% no-repeat padding-box;
  padding-bottom: 40px;

  .suche-neu-inner {
    max-width: var(--ausrichtung-inner);
    width: 100%;
    padding: 40px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .title-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      margin-bottom: 20px;
    }

    .suche-module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      .modul-berufsbezeichnung {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
        }
      }

      .modul-branche {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
          margin-left: 20px;
          margin-right: 20px;
        }

        select {
          width: 100%;
        }
      }

      .modul-ausstellersuche {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
        }
      }

      .search {
        width: 100%;
      }
    }

    .suche-map {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .map-container {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 50%;
          padding-right: 20px;
        }

        .karten-label {
          padding: 10px;
          color: white;
          font-weight: 500;
          cursor: pointer;
          width: 100%;

          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        .karte {
          width: 100%;
          display: none;

          &.show {
            display: inline-block;
          }

          @media screen and (min-width: 768px) {
            display: inline-block;
          }
        }
      }

      .myscroll {
        padding: 0;
        height: 500px;
        overflow-y: scroll;

        ul {
          padding-left: 0;
        }

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

      }

    }
  }
}

.title {
  width: 100%;
  padding: 20px;
  font: normal normal 500 24px/32px Roboto;
  letter-spacing: 0px;
  color: #0c4760;
  opacity: 1;
}

.document-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (min-width: 480px) {
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) {
    padding: 0 20px;
  }

  img {
    width: 100%;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  .content {
    text-align: center;
    height: 100%;

    @media screen and (min-width: 768px) {
      width: 50%;
      padding-left: 20px;
      text-align: left;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .top {
      text-align: center;
      font: normal normal normal 12px/24px Roboto;
      letter-spacing: 0px;
      color: #0c4760;

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }

    .msg {
      text-align: center;
      font: normal normal 500 18px/24px Roboto;
      letter-spacing: 0px;
      color: #0c4760;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }

    .btn {
      border: 1px solid #00A650;
      opacity: 1;
      padding: 10px 20px;
      display: inline-block;
      margin-bottom: 20px;

      a {
        text-align: center;
        font: normal normal 500 22px/29px Roboto;
        letter-spacing: 0px;
        color: #0c4760;
        opacity: 1;
      }
    }
  }

  .left, .right {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    flex-direction: row;

    @media screen and (min-width: 480px) {

    }
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
    }
    @media screen and (min-width: 1024px) {
      width: 50%;
    }
  }
}

.display-switcher {
  display: flex;
  flex-direction: row;
  //margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    //margin-top: 40px;
    margin-bottom: 40px;
  }

  .switch-to-3d, .switch-to-2d {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;

    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }

    > img {
      width: 100%;
      border: 1px solid #00A650;
      border-bottom: none;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #00A650;
      padding: 20px;

      > img {
        max-width: 60px;
        width: 100%;
        padding-right: 10px;
      }
    }
  }

  .switch-to-2d {
  }
}


.firmenliste {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 0px;

    @media screen and (min-width: 480px) {
      justify-content: space-between;
    }

    li {
      width: 100%;
      width: calc(50% - 10px);
      background-color: white;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;

      @media screen and (min-width: 480px) {
        width: calc(50% - 10px);
      }
      @media screen and (min-width: 768px) {
        width: calc(33.33333333% - 10px);
      }
      @media screen and (min-width: 1024px) {
        width: calc(20% - 10px);
      }

      &:hover {
        .overlay {
          max-height: 1000px;
        }
      }

      .logo {
        padding: 20px;

        img {
          width: 100%;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 20px;
        background-color: var(--color-second);
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        max-height: 0;
        overflow: hidden;
        hyphens: auto;
        white-space: normal;

        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;

        @media screen and (min-width: 480px) {
          font-size: 22px;
          line-height: 1.3;
        }
        @media screen and (min-width: 1024px) {
          font-size: 20px;
          line-height: 1.2;
        }
      }
    }
  }
}

.newhallenbox {
  padding: 0 20px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      flex-basis: auto;
      flex-grow: 1;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      a {
        text-align: left;
        font: normal normal 500 24px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }
}

.ueberschrift {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50px;
  line-height: 20px;
  background-color: white;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 10px 30px;
  font-size: 20px;
  border: 1px solid rgb(250, 155, 0);
  color: rgb(250, 155, 0);
}

.ausrichtung {
  max-width: 1040px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #196c37;
  background-color: white;
  padding: 6px 15px;
  color: #196c37;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
}

.search {
  border: 1px solid #00A650;
  background-color: white;
  background-image: url("../assets/INSIDERTREFF_Lupe.svg");
  background-repeat: no-repeat;
  padding: 6px 15px;
  padding-left: 40px;
  background-size: 15px 15px;
  background-position: 10px center;
  color: #0c4760;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
}

input[type="text"]::placeholder {
  font-size: 14px;
  font-style: italic;
  opacity: 1;
}

.home {
  padding-bottom: 60px;
}

.box {
  padding: 0px;
  margin-right: 20px;
  margin-left: 20px;
  @media (max-width: 768px) {

    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
    line-height: 28px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;

      border-radius: 0;
      transition: all .2s;

      img {

        transition: all .2s;
      }

      &:hover {

        transform: scale(1.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, .6);

        img {
          transform: scale(1.25);
        }
      }

      a {
        font-family: "PT Sans", sans-serif;
        display: block;
        color: #333;
        padding: 1px;
      }
    }
  }

  &.newhallenbox {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      li {
        flex-basis: 31%;
        flex-grow: 1;
        text-align: center;
        border-radius: 0;

        @media (max-width: 850px) {
          flex-basis: 50%;
        }

        a {
          padding: 10px;
          color: #fff;
        }
      }
    }
  }

  &.ausstellerbox {


    ul {
      li {

        width: 19%;
        text-align: center;
        box-sizing: border-box;
        flex-grow: 1;
        @media (max-width: 1024px) {
          width: 25%;
        }
        @media (max-width: 768px) {
          width: 40%;
        }
        @media (max-width: 480px) {
          width: auto;
        }

        a {

        }
      }
    }
  }

  h2 {
    margin-top: 0;
  }
}

a {
  text-decoration: none;
}
</style>
