import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import VueMatomo from 'vue-matomo'


Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://statistik.eno-intern.de/',
  siteId: '7',

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: true,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

})



Vue.config.ignoredElements = [
  'a-box',
  'a-scene',
  'a-assets',
  // Use a `RegExp` to ignore all elements that start with "ion-"
  // 2.5+ only
  /^a-/,
]
new Vue({
  data: {
    loading: false,
    chatvisible: false,
    dsvisible: true,
    fragebogenButton: false,
    Einleitungtitle: '',
    Einleitung: '',
    Einleitungrechts: '',
    displaymode: '3d',
    teilnehmer: [],
    hallen: [
      {id: 2, name: 'Industrie I', mcolor: '#0c4760'},
      {id: 21, name: 'Industrie II', mcolor: '#146384'},
       {id: 3, name: 'Handwerk', mcolor: '#15b1dd'},
      {id: 1, name: 'Büro | Dienstleistungen | Öffentlicher Dienst', mcolor: '#038e83'},
     // {id: 4, name: 'Handel | Ernährung | Landwirtschaft', mcolor: '#196422'},
      {id: 5, name: 'Gesundheit | Pflege | Soziales', mcolor: '#01BA5A'},
      {id: 6, name: 'Freizeit | Tourismus | Verkehr | Logistik', mcolor: '#009951'},
    ],
    lageoverlay: null,
    consent: {
      osm: false,
    },
    displayConsentManager: true,
  },
  watch: {
    consent: {
      handler() {
        console.log('consent data changed!')
        localStorage.setItem('consent', JSON.stringify(this.consent))
      },
      deep: true,
    },
  },
  mounted() {

    //document.getElementById('show-modal-dialog').addEventListener('click', () => {
    //  document.getElementById('dialog').showModal()
    //})
    document.getElementById('close-dialog').addEventListener('click', () => {
      document.getElementById('dialog').close()
    })
    document.getElementById('close-dialog2').addEventListener('click', () => {
      document.getElementById('dialog').close()
    })
    document.getElementById('close-dialog3').addEventListener('click', () => {
      document.getElementById('dialog').close()
    })

    if (localStorage.getItem('consent')) {
      console.log('loaded consent data!')

      this.displayConsentManager = false

      let tmp_consent = JSON.parse(localStorage.getItem('consent'))
      console.log(tmp_consent)
      this.consent = tmp_consent
    } else {
      document.getElementById('dialog').showModal()
    }


    this.loading = true
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'firma/apimesse?2021=1')
      .then(response => {
        //console.log(response.data);
        this.teilnehmer = response.data.firmen
        this.Einleitungtitle = response.data.Einleitungtitle
        this.Einleitung = response.data.Einleitung
        this.Einleitungrechts = response.data.Einleitungrechts
      })
      .catch(error => {
        console.log(error)
        alert(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
  },
  router,
  render: h => h(App),
}).$mount('#app')
