<template>
  <div class="home">
    <Header :started="false"/>

    <div class="header-banner">
      <VueSlickCarousel :arrows="false" :dots="true" :slidesToShow="1" :autoplay="true"
                        :infinite="true" :speed="500"
                        class="slider">
        <div class="img-container">
          <img src="../assets/header03.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">Meine Arbeit. Mein Zuhause</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>

        <div class="img-container">
          <img src="../assets/header02.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">In der Heimat durchstarten</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>

        <div class="img-container">
          <img src="../assets/header01.jpg"/>

          <div class="overlay-container">
            <div class="overlay-container-inner ausrichtung">
              <div class="overlay-title">Landkreis Meißen</div>
              <div class="overlay-title-sub">Ihre Heimat. Ihre Zukunft</div>
            </div>
          </div>
          <div class="overlay-welle">
            <img src="../assets/header-welle.png">
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <div class="ausrichtung">

      <div class="page-title">
        <div class="top">
          <div class="title">Kommen und Bleiben</div>
          <div class="img"><img src="../assets/meine-region.png" alt="Meine Region"/></div>
        </div>
        <div class="bottom">
          <img src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>


      <div class="box starttext" style="background-color: transparent;">
        <div></div>
        <div style="width: 100%; box-sizing: border-box; margin-bottom: 20px;">
          Sehr geehrte Besucherin, sehr geehrter Besucher,
        </div>
        <div class="custom">
          <p style="text-align: justify;">Weihnachten naht – und damit auch der Start unserer dritten Fachkräftemesse
            „Kommen und Bleiben. Meine Region.“ am 27. Dezember 2023.</p>
          <p style="text-align: justify;">Wir laden Sie sehr herzlich ein, ins Gespräch mit den rund 50 Ausstellern zu
            kommen und sich über die angebotenen offenen Stellen in den Unternehmen zu informieren. Die ungezwungene,
            entspannte Messeatmosphäre und die großzügigen Räumlichkeiten des BSZ Meißen bieten auch in diesem Jahr
            beste Voraussetzungen für einen vielseitigen und intensiven Austausch.</p>
          <p>Die Fachkräftemesse des Landkreises Meißen konnte im Jahr 2022 erstmalig als Präsenzveranstaltung
            stattfinden. Die große Resonanz und das überwiegend positive Feedback der Besuchenden und Aussteller waren
            uns Freude und Ansporn zugleich, die diesjährige Fachkräftemesse weiter zu optimieren. So haben wir das
            Angebot in diesem Jahr um freie Ausbildungs-/Studienplätze erweitert. In gewohnter Weise lädt auch unser
            virtuelles Messegelände im Zeitraum 27. Dezember 2023 bis 15. Januar 2024 zu einem Besuch ein.</p>
        </div>
        <div class="custom">
          <p style="text-align: justify;">Wir freuen uns, wenn Sie (wieder) vorbeischauen. Vielleicht haben Sie auch Freunde, Nachbarn, Familienmitglieder, die sich beruflich verändern oder in den Landkreis Meißen zurückkehren möchten – dann erzählen Sie von unserer Veranstaltung und bringen Sie andere Interessierte gleich mit.</p>
          <p style="text-align: justify;">Unsere Fachkräftemesse „Kommen und Bleiben. Meine Region.“ lebt von Ihnen. Von Ihrer Verbundenheit zu Ihrem Landkreis; von Ihrem Willen, in Ihre Heimat zurückzukehren; von Ihrer Überzeugung, Ihre Arbeitskraft zukünftig oder weiterhin hier einzusetzen und nicht zuletzt von der wertschätzenden engagierten Gemeinschaft, die Ihnen und uns wichtig ist und die in unserem Landkreis gelebt wird.</p>
          <p style="text-align: justify;">In diesem Sinne: Kommen Sie und bleiben Sie – wir freuen uns auf ein Wiedersehen am 27.12.2023 von 10 Uhr bis 13 Uhr im BSZ Meißen.</p>
          <p style="text-align: justify;">Bis dahin wünschen wir Ihnen eine besinnliche Adventszeit und ein frohes Weihnachtsfest.</p>
          <p style="text-align: justify;">Mit herzlichen Grüßen, Ihr Messeteam</p>
        </div>
      </div>


      <div class="page-title"></div>

      <div class="box starttext" style="background-color: transparent;">
        <div style="width: 100%; box-sizing: border-box; margin-bottom: 20px;display: none"></div>
        <div></div>
        <div class="custom">
          <video controls playsinline src="videos/20230112_Fachkräftemesse_final.mp4" style="width: 100%"></video>
        </div>
        <div class="custom">
          <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow="1" :autoplay="true"
                            :infinite="true" :speed="500"
                            class="slider slider-small">

            <template #prevArrow>
              <div class="custom-arrow-left"></div>
            </template>

            <template #nextArrow>
              <div class="custom-arrow-right"></div>
            </template>

            <div class="img-container">
              <img src="images/IMG_4164.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_4173.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_4177.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_4179.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_4184.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6005.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6007.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6019.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6043.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6070.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6075.JPG"/>
            </div>
            <div class="img-container">
              <img src="images/IMG_6076.JPG"/>
            </div>

          </VueSlickCarousel>
        </div>
      </div>


      <div class="page-title sub" >
        <div class="top">
          <div class="subtitle">Unsere Aussteller – Fachkräftemesse 2023</div>
        </div>
        <div class="bottom">
          <img style="max-width: 300px" src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>


      <ausstellerresultliste :aussteller="aussteller"/>


      <div class="page-title sub">
        <div class="top">
          <div class="subtitle">Finden Sie den passenden Job in Ihrer Nähe</div>
        </div>
        <div class="bottom">
          <img style="max-width: 300px" src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>

      <div class="map-container" style="flex: 0 0 45%;">
        <div v-if="!$root.consent.osm" class="karten-label" @click="activatemap">Karte anzeigen</div>
        <Punktkarte v-if="$root.consent.osm" class="karte" :class="showmap ? 'show' : ''" :teilnehmer="aussteller"
                    :permanenttooltip="false"/>
        <Punktkarteleer v-if="!$root.consent.osm"/>
      </div>



      <div class="page-title sub">
        <div class="top">
          <div class="subtitle">Die zweite Fachkräftemesse 2022 in Zahlen ...</div>
        </div>
        <div class="bottom">
          <img style="max-width: 300px" src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>

      <div class="container-3er">
        <countup :zahl="200" :text="'Besucher waren am 27. Dezember 2022 im Berufsschulzentrum Meißen zu Gast.'"></countup>
        <countup :zahl="50" :text="'Unternehmen des Landkreises Meißen präsentierten sich als Aussteller.'"></countup>
        <countup :zahl="1" :text="'Messetag wurde in Präsenz von 10.00 – 13.00 Uhr durchgeführt'"></countup>
        <countup :zahl="2000" :text="'Interessente besuchten die Fachkräftemesse virtuell.'"></countup>
        <countup :zahl="300" :text="'freie Arbeitsstellen wurden auf der Fachkräftemesse angeboten.'"></countup>
        <countup :zahl="20" :text="'virtuelle Messetage waren eine gute Ergänzung zur Präsenzmesse.'"></countup>
      </div>

      <div class="page-title sub">
        <div class="top">
          <div class="subtitle">Sie sind auf der Suche nach einem Jobangebot?<br> Na dann los ...</div>
        </div>
        <div class="bottom">
          <img style="max-width: 300px" src="../assets/welle.png" alt="Trenner"/>
        </div>
      </div>

      <div class="container-3er-mit-bild">
        <a href="https://www.kreis-meissen.de/Aktuelles/Karriere-im-Landratsamt/Stellenausschreibungen/" target="_blank">
          <div class="item">
            <div class="bild"><img src="../assets/logo/Landkreislogo_farbig.svg" alt="Landkreis Meißen Logo"/></div>
            <div class="text h2lookalike">Arbeits- und Ausbildungsstellen im Landratsamt Meißen</div>
          </div>
        </a>
        <a href="https://www.air-meissen.de/" target="_blank">
          <div class="item">
            <div class="bild"><img src="images/Air_Meissen.png" alt="Air Meißen Logo"/></div>
            <div class="text h2lookalike">Arbeits- und Ausbildungsstellen auf dem Job-Portal Air-Meißen</div>
          </div>
        </a>
        <a href="http://jc-stellenangebote.kreis-meissen.org/stellenliste.php" target="_blank">
          <div class="item">
            <div class="bild"><img src="images/LogoJobcenter.jpg" alt="Kommunale Jobcenter Logo"/></div>
            <div class="text h2lookalike">Arbeits- und Ausbildungsstellen auf dem Stellenportal des Jobcenters des
              Landkreises Meißen
            </div>
          </div>
        </a>
      </div>

    </div>


  </div>
</template>
<script>

import Header from '../components/header/header'

import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
// optional style for arrows & dots
import 'slick-vuejs/dist/slick-vuejs-theme.css'
import Punktkarte from "@/components/landingpage/Punktkarte.vue";
import Punktkarteleer from "@/components/Punktkarteleer.vue";
import Ausstellerresultliste from "@/components/landingpage/austellerresultliste.vue";
import Countup from "@/components/landingpage/countup.vue";


export default {
  name: 'Home',
  components: {Countup, Ausstellerresultliste, Punktkarteleer, Punktkarte, Header, VueSlickCarousel},
  data() {
    return {
      showmap: false,
      ausstellersearch: '',
      overlayVideo: null,
      publicPath: process.env.BASE_URL,
      wwwPath: process.env.VUE_APP_API_ENDPOINT,
      hallesearch: null,
      slickbreakpoints: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ],
    }
  },
  computed: {
    aussteller() {
      return this.$root.teilnehmer
    },

  },
  methods: {
    activatemap() {
      this.showmap = true
      //karte neu positionieren?
    },
    openLKInfo() {
      window.open(this.wwwPath + '/uploads/startseite/Landkreisinformationen_Meissen.pdf', '_blank')
    },
    openLKAp() {
      window.open(this.wwwPath + '/uploads/startseite/Ansprechpartner_im_LRA_Meissen_01.pdf', '_blank')
    },
    openLKDaten() {
      window.open(this.wwwPath + '/uploads/Flashstick_FKM_2022.pdf', '_blank')
    },
    animateValue(id, start, end, duration) {
      var range = end - start;
      var current = start;
      var obj = document.getElementById(id);
      var starttime;
      var fn = (ms) => {
        let progress = 0;
        if(starttime === undefined) {
          starttime = ms;
        } else {
          progress = ms - starttime;
          if (progress >= duration) {
            // the `+ ' ' + progress + 'ms';` is just to show the duration, wouldn't use that in final code
            current = end.toLocaleString()  + ' ' + progress + 'ms';
          } else {
            current = start + Math.floor(progress/duration * range);
          }
        }
        obj.innerHTML = current.toLocaleString();
        if (progress < duration) {
          requestAnimationFrame(fn);
        }
      };
      requestAnimationFrame(fn);
    }
  }
}
</script>
<style lang="scss">
.slider-small {
  .slick-list {

    .slick-track {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 265px !important;

      .slick-slide {
        display: flex !important;
        align-items: center !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.slider-small {
  .custom-arrow-left.slick-prev {
    left: 10px;
    z-index: 9999;
    top: 47%;

    &:before {
      background-color: white;
      border-radius: 200px;
      border: 0px solid transparent;
    }
  }
  .custom-arrow-right.slick-next {
    right: 30px;
    z-index: 9999;
    top: 47%;

    &:before {
      background-color: white;
      border-radius: 200px;
      border: 0px solid transparent;
    }
  }
}
.header-banner {
  .slider {
    .img-container {
      position: relative;

      @media (max-width: 767px) {
        height: 80vw;
      }

      .overlay-container {
        position: absolute;
        bottom: 40%;
        width: 100%;
        z-index: 9;

        @media (max-width: 767px) {
          display: none;
        }

        .overlay-container-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .overlay-title {
            display: inline-block;
            background-color: rgba(10, 62, 84, 0.85);
            padding: 20px;
            line-height: 1;

            text-align: left;
            font-weight: normal;
            font-size: clamp(2.5rem, calc(2.0934rem + 1.8072vw), 3.25rem);;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            margin-left: 20px;
          }

          .overlay-title-sub {
            display: inline-block;
            background-color: white;
            padding: 10px;
            line-height: 1;

            text-align: left;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 24px;
            letter-spacing: 0;
            color: #0c4760;
            opacity: 1;
            margin-top: -10px;
            margin-left: 230px;
          }
        }
      }

      .overlay-welle {
        position: absolute;
        bottom: 23%;
        z-index: 8;

        @media (max-width: 767px) {
          bottom: 17%;
        }
      }
    }

    img {
      border: none;

      @media (max-width: 767px) {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

.slider {
  margin: 0;

  .slick-dots {
    bottom: 21px;

    li {
      margin: 0;

      &.slick-active {
        button::before {
          color: rgba(10, 62, 84, 0.85);
        }
      }

      button::before {
        font-size: 13px;
        opacity: 1;
        color: white;
      }
    }
  }

  .slick-prev, .slick-next {
    top: 45%;
  }

  .slick-prev {
    left: 100px;

    @media screen and (min-width: 768px) {
      left: 10px;
    }
  }

  .slick-next {
    right: 10px;

    @media screen and (min-width: 768px) {
      right: 10px;
    }
  }

  .slick-prev::before, .slick-next::before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #000000;
    content: '';
    background: url('../assets/icons/pfeil-links.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 30px;
    height: 30px;
    display: inline-block;

    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;

    }
  }

  .slick-next::before {
    transform: rotateY(180deg);
  }

  img {
    width: 100%;
    height: auto;
    border: 1px solid #00A650;
    border-bottom: 0;

  }

  .playicon {
    width: 55px;
    height: 55px;

    position: absolute;
    transition: color 250ms;
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: red;
    }

    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 18%;
  }

  .image {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    .text {
      border: 1px solid #00A650;
      box-sizing: border-box;
      width: 100%;
      text-transform: none;
      padding: 10px 10px;
      margin-left: 0px;
      min-height: 70px;

      text-align: left;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0px;
      color: var(--color-main);
      opacity: 1;
      //height: 81px;
    }
  }

  /*
   overlay text
    .image {
      position: relative;
      box-sizing: border-box;
      cursor: pointer;

      .text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        background-color: rgba(68, 68, 68, 0.85);
        padding: 10px 20px;
        margin-left: 10px;
      }
    }
  */
}

.slider-boxes {
  margin-top: 40px;
  max-width: 80%;
  margin: 40px auto;

  @media screen and (min-width: 1024px) {
    max-width: calc(100% - 20px);
  }

  .slick-slide {
    padding: 10px !important;

  }


}
</style>
<style scoped lang="scss">

.page-title {
  display: flex;
  flex-direction: column;
  padding: 60px 0px 40px 0px;

  @media screen and (min-width: 768px) {
    padding: 80px 20px 40px 20px;
  }

  &.sub {
    .bottom {
      margin-left: 0px;

      @media screen and (min-width: 768px) {
        margin-left: -20px
      }
    }
  }

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    .title {
      text-align: left;
      margin-right: 20px;
      width: 100%;
      margin-bottom: 20px;

      width: 100%;
      padding: 20px;
      font: normal normal 500 24px/32px Roboto;
      letter-spacing: 0px;
      color: #0c4760;
      opacity: 1;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
      }
    }

    .subtitle {
      text-align: left;
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      font-size: 28px;
      letter-spacing: 0px;
      color: var(--color-main);
      opacity: 1;
      margin-right: 20px;
      width: 100%;
      margin-bottom: 20px;
      line-height: 1.2;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
      }
    }

    .img {
      width: 183px;

      img {
        width: 100%;
      }
    }
  }

  .bottom {
    margin-top: 10px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
    }

    img {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }
}

.starttext {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  > div.custom {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 50%;

      &:nth-child(3) {
        padding-right: 10px;
      }
      &:nth-child(4) {
        padding-left: 10px;
      }
    }
  }
}

.map-container {
  width: 100%;

  @media screen and (min-width: 768px) {

  }

  .karten-label {

    @media screen and (min-width: 768px) {
    }
  }

  .karte {
    width: 100%;
    display: none;

    &.show {
      display: inline-block;
    }

    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }
}

.suche-neu {
  width: 100%;
  background: transparent linear-gradient(243deg, #00AAB7 0%, #009951 100%) 0% 0% no-repeat padding-box;
  padding-bottom: 40px;

  .suche-neu-inner {
    max-width: var(--ausrichtung-inner);
    width: 100%;
    padding: 40px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .title-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      margin-bottom: 20px;
    }

    .suche-module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      .modul-berufsbezeichnung {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
        }
      }

      .modul-branche {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
          margin-left: 20px;
          margin-right: 20px;
        }

        select {
          width: 100%;
        }
      }

      .modul-ausstellersuche {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 30%;
        }
      }

      .search {
        width: 100%;
      }
    }

    .suche-map {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .map-container {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 50%;
          padding-right: 20px;
        }

        .karten-label {
          padding: 10px;
          color: white;
          font-weight: 500;
          cursor: pointer;
          width: 100%;

          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        .karte {
          width: 100%;
          display: none;

          &.show {
            display: inline-block;
          }

          @media screen and (min-width: 768px) {
            display: inline-block;
          }
        }
      }

      .myscroll {
        padding: 0;
        height: 500px;
        overflow-y: scroll;

        ul {
          padding-left: 0;
        }

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

      }

    }
  }
}

.document-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (min-width: 480px) {
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) {
    padding: 0 20px;
  }

  img {
    width: 100%;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  .content {
    text-align: center;
    height: 100%;

    @media screen and (min-width: 768px) {
      width: 50%;
      padding-left: 20px;
      text-align: left;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .top {
      text-align: center;
      font: normal normal normal 12px/24px Roboto;
      letter-spacing: 0px;
      color: #0c4760;

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }

    .msg {
      text-align: center;
      font: normal normal 500 18px/24px Roboto;
      letter-spacing: 0px;
      color: #0c4760;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }

    .btn {
      border: 1px solid #00A650;
      opacity: 1;
      padding: 10px 20px;
      display: inline-block;
      margin-bottom: 20px;

      a {
        text-align: center;
        font: normal normal 500 22px/29px Roboto;
        letter-spacing: 0px;
        color: #0c4760;
        opacity: 1;
      }
    }
  }

  .left, .right {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    flex-direction: row;

    @media screen and (min-width: 480px) {

    }
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
    }
    @media screen and (min-width: 1024px) {
      width: 50%;
    }
  }
}

.display-switcher {
  display: flex;
  flex-direction: row;
  //margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    //margin-top: 40px;
    margin-bottom: 40px;
  }

  .switch-to-3d, .switch-to-2d {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;

    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }

    > img {
      width: 100%;
      border: 1px solid #00A650;
      border-bottom: none;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #00A650;
      padding: 20px;

      > img {
        max-width: 60px;
        width: 100%;
        padding-right: 10px;
      }
    }
  }

  .switch-to-2d {
  }
}


.firmenliste {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 0px;

    @media screen and (min-width: 480px) {
      justify-content: space-between;
    }

    li {
      width: 100%;
      width: calc(50% - 10px);
      background-color: white;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;

      @media screen and (min-width: 480px) {
        width: calc(50% - 10px);
      }
      @media screen and (min-width: 768px) {
        width: calc(33.33333333% - 10px);
      }
      @media screen and (min-width: 1024px) {
        width: calc(20% - 10px);
      }

      &:hover {
        .overlay {
          max-height: 1000px;
        }
      }

      .logo {
        padding: 20px;

        img {
          width: 100%;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 20px;
        background-color: rgba(10, 62, 84, 0.95);
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        max-height: 0;
        overflow: hidden;
        hyphens: auto;
        white-space: normal;

        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;

        @media screen and (min-width: 480px) {
          font-size: 22px;
          line-height: 1.3;
        }
        @media screen and (min-width: 1024px) {
          font-size: 20px;
          line-height: 1.2;
        }
      }
    }
  }
}

.newhallenbox {
  padding: 0 20px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      flex-basis: auto;
      flex-grow: 1;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      a {
        text-align: left;
        font: normal normal 500 24px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }
}

.ueberschrift {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50px;
  line-height: 20px;
  background-color: white;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: normal;
  font-family: "Museo Slab W01_300";
  cursor: pointer;
  margin-bottom: 30px;
  padding: 10px 30px;
  font-size: 20px;
  border: 1px solid rgb(250, 155, 0);
  color: rgb(250, 155, 0);
}

.ausrichtung {
  max-width: 1040px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #196c37;
  background-color: white;
  padding: 6px 15px;
  color: #196c37;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
}

.search {
  border: 1px solid #00A650;
  background-color: white;
  background-image: url("../assets/INSIDERTREFF_Lupe.svg");
  background-repeat: no-repeat;
  padding: 6px 15px;
  padding-left: 40px;
  background-size: 15px 15px;
  background-position: 10px center;
  color: #0c4760;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
}

input[type="text"]::placeholder {
  font-size: 14px;
  font-style: italic;
  opacity: 1;
}

.home {
  padding-bottom: 60px;
}

.box {
  padding: 0px;
  margin-right: 20px;
  margin-left: 20px;
  @media (max-width: 768px) {

    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
    line-height: 28px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;

      border-radius: 0;
      transition: all .2s;

      img {

        transition: all .2s;
      }

      &:hover {

        transform: scale(1.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, .6);

        img {
          transform: scale(1.25);
        }
      }

      a {
        font-family: "Museo Slab W01_300", sans-serif;
        display: block;
        color: #333;
        padding: 1px;
      }
    }
  }

  &.newhallenbox {


    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      li {
        flex-basis: 31%;
        flex-grow: 1;
        text-align: center;
        border-radius: 0;

        @media (max-width: 850px) {
          flex-basis: 50%;
        }

        a {
          padding: 10px;
          color: #fff;
        }
      }
    }
  }

  &.ausstellerbox {


    ul {
      li {

        width: 19%;
        text-align: center;
        box-sizing: border-box;
        flex-grow: 1;
        @media (max-width: 1024px) {
          width: 25%;
        }
        @media (max-width: 768px) {
          width: 40%;
        }
        @media (max-width: 480px) {
          width: auto;
        }

        a {

        }
      }
    }
  }

  h2 {
    margin-top: 0;
  }
}

a {
  text-decoration: none;
}
.slider img {
  border: none !important;
}

.container-3er {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  .item {
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 480px) {
      padding: 20px;
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 33.3333333333%;
    }

    .number {
      font-size: 8.8vw;
      line-height: 1.4;

      @media screen and (min-width: 1024px) {
        font-size: 120px !important;
      }
    }
    .text {
      font-size: 16px;
      line-height: 1.4;
      color: var(--color-main);
    }
  }
}

.container-3er-mit-bild {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  a {
    width: 100%;
    box-sizing: border-box;
    //-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
    //box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
    margin-bottom: 20px;

    @media (min-width: 480px) {
      margin: 20px;
      width: calc(50% - 40px);
      margin-bottom: 0px;
    }
    @media (min-width: 768px) {
      width: calc(33.3333333333% - 40px);
    }
  }

  .item {
    width: 100%;
    box-sizing: border-box;


    @media (min-width: 480px) {

    }
    @media (min-width: 768px) {

    }

    .bild {
      background-color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
      height: auto;

      @media (min-width: 480px) {
        padding: 20px;
        height: 280px;
      }

      @media (min-width: 768px) {
        padding: 20px;
        height: 280px;
      }

      img {
        width: 100%;


        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
    .text {
      background-color: #fff;
      //color: var(--color-main);
      padding: 20px;
      box-sizing: border-box;
      //font-size: 20px;
      //line-height: 1.3;
      //font-weight: normal;
      hyphens: auto;

      @media (min-width: 768px) {
        //font-size: 1.6vw;
        //line-height: 1.3;
      }
    }
  }
}

</style>

<style lang="scss">
.container-3er {
  .item {

    @media (min-width: 480px) {
    }
    @media (min-width: 768px) {
    }

    .number {
      font-size: 8.8vw;
      line-height: 1.4;

      @media screen and (min-width: 1024px) {
        font-size: 120px !important;
      }
    }
  }
}
</style>
