<template>
  <div id="footerrahmen" class="noprint">
    <div id="footer" class="ausrichtung-inner">

      <div class="title">Unsere Kooperationspartner</div>

      <div class="logo-footer">
        <img src="../../assets/logo/WRM_logo_4c_300dpi.png">
        <img src="../../assets/logo/KHS_Logo.jpg">
        <img src="../../assets/logo/KULO_DD-H_4c.jpg">
        <img src="../../assets/logo/Logo HWK-DD.jpg">
        <img src="../../assets/logo/07901_Riesa_Claim_C.png">
      </div>

      <div class="sponsor">
        <img class="logo2" src="./../../assets/logo/Fachkraefte-Allianz.png" alt="Fachkräfte Allianz - Logo"/>
        <img class="logo1" src="./../../assets/logo/sachsen-logo.png" alt="Sachsen - Logo"/>
        <div class="text">Diese Maßnahme wird mitfinanziert mit Steuermitteln auf der Grundlage des von den Abgeordneten
          des Sächsischen Landtags beschlossenen Haushaltes.
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="inner">
        <img src="../../assets/logo/footer-loewe.png">
        <div class="claim">&copy; Meißen – Kommen und Bleiben – MEIne ReGion.</div>
        <div class="links">
          <router-link to="/Barrierefreiheitserklaerung">Barrierefreiheitserklärung</router-link>
          <router-link to="/Datenschutz">Datenschutz</router-link>
          <router-link to="/Impressum">Impressum</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
/* COLOR SETTINGS */
$color-main: rgba(39, 59, 110, 1);
$color-main2: rgba(126, 126, 126, 1);
$color-main3: rgba(199, 199, 200, 1);

$color-second: rgb(25, 108, 55);
$color-second-80: rgba(0, 152, 145, 0.8);
$color-second2: rgba(127, 94, 129, 1);
$color-second3: rgba(217, 179, 197, 1);

$color-third: rgba(233, 76, 102, 1);
$color-third2: rgba(242, 147, 152, 1);
$color-third3: rgba(250, 209, 208, 1);

$color-fourth: #333333;
$color-inverted: white;

$color-blau: #53b4c9;
$color-orange: #fa9b00;
$color-orange-80: rgba(250, 155, 0, 0.8);

$color-grau-dunkel: #333;
$color-gruen-hell: #a4bd46;


/* Meißen farben */
$color-lila: #0070B9;
$color-gruen-dunkel: #00B253;

/* PAGE SETTINGS */
$max-width: 900px;
$max-width-inner: 768px;
$ausrichtung: $max-width;
$ausrichtung-inner: $max-width-inner;

$display-1024: 1024px;
$display-900: 900px;
$display-768: 768px;
$display-600: 600px;
$display-480: 480px;
$display-360: 360px;
$display-mobile-breakpoint: $display-900;
$display-mobile-breakpoint-alternative: $display-480;

$abstand-smaller: 10px;
$abstand-small: 20px;
$abstand-normal: 40px;
$abstand-big: 80px;


$font-family-h1: "Museo Slab W01_300", sans-serif;
$h1-font-size: 40px;
$h1-line-height: 48px;
$h1-color: $color-grau-dunkel;

$font-family-h2: "Museo Slab W01_300", sans-serif;
$h2-font-size: 25px;
$h2-line-height: 35px;
$h2-color: $color-grau-dunkel;

$font-family-h3: "Museo Slab W01_300", sans-serif;
$h3-font-size: 22px;
$h3-line-height: 30px;
$h3-color: $color-gruen-dunkel;

$font-family-text: "Clan Offc Narrow", sans-serif;
$text-font-size: 16px;
$text-line-height: 28px;
$text-color: $color-grau-dunkel;

$font-family-link: "Clan Offc Narrow", sans-serif;
$link-font-size: 16px;
$link-line-height: 28px;
$link-color: $color-gruen-dunkel;

#footerrahmen {
  background: white;
  margin: 0 auto;
  width: 100%;
  margin-top: 40px;

  @media screen and (min-width: $display-768) {
    margin-top: 60px;
  }

  #footer {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    @media screen and (min-width: $display-768) {

    }

    .title {
      margin-bottom: 20px;
      text-align: center;
      font: normal normal 500 25px/29px Roboto;
      letter-spacing: 0px;
      color: #0c4760;
      opacity: 1;

      @media screen and (min-width: $display-768) {
        margin-bottom: 20px;
      }
    }

    .logo-footer {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;

      @media screen and (min-width: $display-768) {
        flex-wrap: nowrap;
      }

      img {
        max-width: 250px;
        width: 100%;
        padding: 20px;

        &:nth-child(1),
        &:nth-child(2) {
          width: auto;
          height: 130px;
          padding: 0 10px;
          padding-bottom: 20px;

          @media screen and (min-width: $display-768) {
            height: 60px;
            width: auto;
            padding: 10px;
          }
          @media screen and (min-width: $display-1024) {
            height: 80px;
            padding: 10px;
          }
        }

        @media screen and (min-width: $display-768) {
          height: 60px;
          width: auto;
          padding: 10px;

          &:nth-child(2) {
            order: 3;
          }
          &:nth-child(5) {
            order: 2;
          }
        }
        @media screen and (min-width: $display-1024) {
          height: 80px;
          padding: 10px;
        }
      }
    }

    .sponsor {
      border: 1px solid #00A650;
      padding: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 40px;

      .logo1 {
        width: auto;
        height: 80px;
      }

      .logo2 {
        width: auto;
        height: 80px;
        margin-right: 20px;
      }

      .text {
        margin-top: 20px;
        width: 100%;
        height: auto;
        text-align: left;
      }

      @media screen and (min-width: $display-768) {
        flex-wrap: nowrap;
        margin-bottom: 60px;

        .logo2 {
          order: 1;
        }
        .logo1 {
          order: 3;
        }
        .text {
          order: 2;
          margin-top: 0;
        }
      }
    }

    div.navgrid a {
      margin-left: 5px;
      margin-right: 5px;
    }

    .firmen-logos {
      margin-top: 40px;
      margin-bottom: 20px;

      img {
        height: 14px;
        padding-left: 15px;
        padding-right: 15px;
        border-right: 1px solid $color-grau-dunkel;
        box-sizing: border-box;
      }

      a {
        &:last-child {
          img {
            border: 0;
          }
        }
      }
    }

    .bottom {
      color: $color-grau-dunkel;
      font-weight: bold;
      font-size: 14px;

      @media screen and (max-width: $display-480) {
        font-size: 12px;
      }
    }
  }


  .footer-bottom {
    background: #0c4760 0% 0% no-repeat padding-box;
    border: 1px solid #707070;

    .inner {
      max-width: var(--ausrichtung);
      width: 100%;
      padding: 40px 20px;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: $display-768) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
      }

      img {
        max-width: 80px;

        @media screen and (min-width: $display-768) {
          order: 2;
          width: 155px;
        }
      }

      .claim {
        margin-top: 20px;
        margin-bottom: 60px;
        text-align: center;
        font: normal normal 500 19px/25px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        width: 100%;

        @media screen and (min-width: $display-768) {
          order: 1;
          margin-top: 0px;
          margin-bottom: 0px;
          width: calc(100% - (155px / 2));
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: $display-768) {
          order: 3;
          width: calc(100% - (155px / 2));
          flex-direction: row;
          justify-content: center;

          a {
            margin: 0 10px;
          }
        }

        a {
          text-align: center;
          font: normal normal normal 16px/24px Roboto;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
        }
      }

    }
  }
}

</style>
