import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },



  {
    path: '/Datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "ds" */ '../views/Datenschutz.vue')
  },
  {
    path: '/Barrierefreiheitserklaerung',
    name: 'Barrierefreiheitserklaerung',
    component: () => import(/* webpackChunkName: "Barrierefreiheitserklaerung" */ '../views/Barrierefreiheitserklaerung.vue')
  },
  {
    path: '/Messeinfo',
    name: 'Messeinfo',
    component: () => import(/* webpackChunkName: "Messeinfo" */ '../views/Messeinfo.vue')
  },

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return {selector: to.hash}
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
