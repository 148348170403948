<template>
  <div class="karte">
    <div style="height: 100%;background-color: rgba(0,0,0,0.18);padding-bottom: 300px;box-sizing: border-box">
      <div style="max-width: 600px;width: 100%;top: 35%;margin: 0 auto;position: relative; padding: 40px;color: white; font-weight: bold">
        Das Element wird im OpenStreetMap-iFrame geladen, wodurch OpenStreetMap Informationen für Analyse- und
        Marketing-Zwecke erhält. Wenn Sie damit einverstanden sind, klicken Sie bitte auf „Akzeptieren“.
        Weitere Informationen finden sie in der
        <router-link to="/datenschutz" style="color: white; text-decoration: underline">Datenschutzerklärung</router-link>
        .<br>
        <button class="button button-inverted button-orange" @click="$root.consent.osm = true;">Akzeptieren</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Punktkarteleer',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
  },
  watch: {},
  mounted() {
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.karte {
  height: 500px;
  margin: 0;

  @media screen and (max-width: 768px) {
    height: 300px;
  }


}
</style>
