<template>
  <div class="header">
    <div class="header-container">
      <div class="logo">
        <router-link to="/">
          <img class="desktop" src="../../assets/logo/Landkreislogo_farbig.svg">
          <img class="mobil" src="../../assets/logo/Landkreislogo_farbig.svg">
        </router-link>
      </div>

      <div class="mobile-menu-icons" @click="showmobilemenu=!showmobilemenu">
        <img class="opener" src="../../assets/icons/menu-open.png" alt="Menü öffnen" />
      </div>

      <div v-if="started" class="menu" :class="(showmobilemenu == true) ? 'show' : ''">
        <a href="#/#suche">Suche</a>
        <a href="#/#aussteller">Aussteller</a>
        <a v-if="false" href="#/#ihremeinung" @click="openFragebogen">Ihre Meinung</a>
      </div>

      <div v-if="!started" class="menu" :class="(showmobilemenu == true) ? 'show' : ''">
        <router-link :to="{ name: 'Home'}">
          Besucher
        </router-link>
        <a href="#/#aussteller">Aussteller</a>

      </div>

    </div>
  </div>
</template>
<style type="text/scss" lang="scss" scoped>
.header {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #7070706F;

  .header-container {
    max-width: var(--ausrichtung);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    position: relative;

    .logo {
      padding: 0;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;

      @media screen and (min-width: 768px) {
        padding-bottom: 20px;
      }

      img {
        max-width: 140px;
        width: 100%;

        &.mobil {
          @media screen and (min-width: 1024px) {
            display: none;
          }
        }

        &.desktop {
          display: none;

          @media screen and (min-width: 1024px) {
            display: inline-block;
            max-width: 140px;
          }
        }
      }
    }

    .mobile-menu-icons {
      margin-right: 20px;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        display: none;
      }

      .opener {
        max-width: 60px;
      }
    }

    .menu {
      display: none;

      position: absolute;
      right: 0;
      flex-direction: column;
      bottom: -195px;
      z-index: 9;
      padding: 20px 0;
      background: white;

      &.show {
        display: flex;
      }

      @media screen and (min-width: 768px) {
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        align-self: flex-start;
      }

      a {
        padding: 10px 20px;

        @media screen and (min-width: 768px) {
          padding-top: 65px;
          box-sizing: border-box;
          margin-bottom: 20px;
          font-weight: bolder;
          font-size: 20px;
          white-space: nowrap;
        }

        &:hover {
          background-color: #0a3e54;
          color: white;
        }
      }
    }
  }
}
</style>
<script>
export default {
  methods: {
    openFragebogen() {
      window._paq.push(['trackEvent', 'click', 'Fragebogen', '', '1'])
      window.open('https://forms.office.com/r/yZgiLGNyu2', '_blank')
    },
  },
  props: {
    started: Boolean
  },
  data() {
    return {
      showmobilemenu: false,
    }
  },

}
</script>
