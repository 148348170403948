<template>
  <div id="app">
    <Landingpage v-if="started===false && $route.name === 'Home'"></Landingpage>

    <div id="nav" v-if="$route.name !== 'Hall' && $route.name !== 'Stand'">
      <div id="logocontainer">
        <!--  <router-link to="/"><img id="logo" src="./assets/logo/Logo_MeineRegion2021.jpg"></router-link>-->
      </div>
    </div>

    <div v-if="this.$root.loading">Loading...</div>



    <router-view v-if="(($route.name === 'Barrierefreiheitserklaerung'||$route.name === 'Impressum'||$route.name === 'Datenschutz'||$route.name === 'Messeinfo')
    || started) && !this.$root.loading" id="view"/>

    <Footer v-if=" $route.name !== 'Hall' && $route.name !== 'Stand'"/>
    <div v-if="started" style="position: fixed;bottom: 20px;right: 20px;display: none">
      <div class="wackeln" @click="openFragebogen()">
        <img src="./assets/anregungen.svg" />
      </div>
    </div>


    <dialog id="dialog" class="v-dialog">
      <h2>Wir nutzen externe Dienste</h2>
      <p>Für ein optimales Benutzererlebnis empfehlen wir Ihnen die externen Dienste zuzulassen</p>

      <div style="margin-bottom: 20px">
        <button id="close-dialog" class="button button-inverted" @click="$root.consent.osm = false;">keine externen Dienste zulassen</button>&nbsp;
        <button id="close-dialog2" class="button" @click="$root.consent.osm = true;">Alle Dienste zulassen</button>
      </div>

      <div class="item">
        <b>OpenStreetMap Service</b><br>
        <button v-if="!$root.consent.osm" class="button button-inverted " @click="$root.consent.osm = true">Dienst zulassen</button>
        <button v-if="$root.consent.osm" class="button button-inverted " @click="$root.consent.osm = false">Dienst nicht zulassen</button>
      </div>

      <div>
        <button id="close-dialog3" class="button" >Auswahl speichern</button>&nbsp;
      </div>
    </dialog>

  </div>
</template>
<style lang="scss">
:root {
  --ausrichtung: 1400px;
  --ausrichtung-inner: 1040px;

  --color-main: #0c4760;
  --color-second: #8194a6;
}

@font-face {
  font-family: "Museo Slab W01_300";
  src: url("./assets/fonts/905336/23674a3a-6e87-4ade-817d-6bd3b6a7d538.woff2") format("woff2"), url("./assets/fonts/905336/4548ac88-af2c-4b63-ac2b-94e9ef6cd1b9.woff") format("woff");
}

@font-face {
  font-family: "Museo Slab W01_500";
  src: url("./assets/fonts/905342/16bb87bf-d138-429b-bc78-dae6c4f109fb.woff2") format("woff2"), url("./assets/fonts/905342/3cf54835-9705-474e-9afa-46f2d59589e7.woff") format("woff");
}

@font-face {
  font-family: 'Clan Offc Narrow';
  src: url('./assets/fonts/ClanOffc-NarrBook.ttf') format('truetype');
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* pt-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/pt-sans-v17-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* pt-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/pt-sans-v17-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* pt-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/pt-sans-v17-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* pt-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/pt-sans-v17-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


* {
  box-sizing: border-box;
}
img {
  vertical-align: bottom;
}

body {
  font-family: "PT Sans", sans-serif;
  color: #0c4760;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0;
  font-weight: normal;

  max-width: 1900px;
  width: 100%;
  margin: 0 auto;

  padding: 0;
  background-color: #fff;

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-size-adjust: none;
  image-rendering: auto !important;
}

.ausrichtung {
  max-width: var(--ausrichtung);
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.ausrichtung-inner {
  max-width: var(--ausrichtung-inner);
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

h1, h2, h3 {
  font-family: "PT Sans", sans-serif;
}
h1, .h1lookalike {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.4;

  text-align: left;
  color: var(--color-main);
  opacity: 1;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
  }
}
h2, .h2lookalike {
  font-family: "PT Sans", sans-serif;
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.3;
  font-style: italic;

  text-align: left;
  color: var(--color-second);
  opacity: 1;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
  }
}
a {
  text-decoration: none;
  text-align: left;
  font-family: 'PT Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  color: #00A650;
}
.button {
  transition: all 0.3s ease-in-out;
  background-color: #f2f2f2;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;

  &:hover, &.button-active {
    background-color: #089122 !important;
    color: #fff !important;
  }

  &.button-uppercase {
    text-transform: uppercase;
  }
  &.button-bold {
    font-weight: bold;
  }
}

@keyframes newshake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes glow {
  to {
    border-color: #c80000;

    box-shadow: 2px 2px 8px #c80000;
  }
}

@-webkit-keyframes glow2 {
  0% {
    border-color: transparent;

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.46);
  }

  95% {
    border-color: transparent;

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.46);
  }
  100% {
    border-color: #c80000;

    box-shadow: 2px 2px 8px #c80000;
  }
}

.wackeln {
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    transform: scale(1.2);
  }
}

.joystick {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

#logo {
  max-height: 90px;
  margin-top: -25px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 10px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
}

.v-dialog {
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4;

  * {
    font-family: "PT Sans", sans-serif;
    font-size: 16px;
    line-height: 1.4;
  }
}

</style>
<script>
import Footer from './components/footer/footer'
import Landingpage from "@/views/Landingpage";

export default {
  components: {Landingpage, Footer},
  methods: {
    openFragebogen() {
      window._paq.push(['trackEvent', 'click', 'Fragebogen', '', '1'])
      window.open('https://forms.office.com/r/yZgiLGNyu2', '_blank')
    },

    countDownTimer() {
      let self = this
      // console.log(new Date( ))
      //let startAt = new Date("12/27/2029 06:00")
      let startAt = new Date("12/27/2023 06:00")

      if (self.started !== false) {
        return;
      }

      let today = new Date()
      let diff = today.getTime() - startAt.getTime();
      if (diff > 0) {
        self.started = true
        return
      }

      setTimeout(() => {
        this.countDownTimer()
      }, 1000)
    },

  },
  data() {
    return {
      started: false
    }
  },

  created() {
    this.countDownTimer()
  },

}
</script>
