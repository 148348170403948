<template>
  <div class="karte">
    <l-map ref="map" :zoom="zoom" :center="center" :options="{scrollWheelZoom:false,attributionControl: false}">
      <l-tile-layer :url="url" :attribution="attribution"/>
      <l-control-attribution position="bottomright" prefix="LEAFLET"></l-control-attribution>
      <l-feature-group ref="features" @ready="bounds">
        <template v-for="(waypoint) in teilnehmer">
          <!--
          <l-marker v-if="waypoint.latitude"
                    :key="waypoint.id"
                    :title="waypoint.Firma"
                    :lat-lng="[waypoint.latitude, waypoint.longitude]"
                    @click="goto(waypoint.id, waypoint.Halle)"
          >
            <l-icon :icon-url="ortmarker"/>
            <l-tooltip :content="waypoint.Firma"/>
          </l-marker>
          -->
          <l-marker v-if="waypoint.latitude"
                    :key="waypoint.id"
                    :title="waypoint.Firma"
                    :lat-lng="[waypoint.latitude, waypoint.longitude]"
                    @click="goto(waypoint.id, waypoint.Halle)">
            <l-icon :icon-url="ortmarker"/>
            <l-tooltip :options="{direction: 'top', permanent: permanenttooltip, opacity: '1'}" style="text-align: center;padding: 10px 15px;box-sizing: border-box;"><b style="text-align: center;font: normal normal 700 13px Roboto;letter-spacing: 0px;color: #0c4760;">{{waypoint.Firma}}</b><div style="text-align: center;font: normal normal 500 10px Roboto;letter-spacing: 0px;color: #0c4760;">{{waypoint.Strasse}}<br>{{waypoint.Plz}} {{waypoint.Ort}}</div></l-tooltip>
          </l-marker>
        </template>
      </l-feature-group>
    </l-map>
  </div>
</template>

<script>
import {LIcon, LMap, LTileLayer, LMarker, LTooltip, LFeatureGroup,LControlAttribution} from 'vue2-leaflet'
import * as L from "leaflet";


export default {
  name: 'Punktkarte',
  components: {
    LControlAttribution,
    LMap,
    LFeatureGroup,
    LTileLayer,
    LTooltip,
    LMarker,
    LIcon,
  },
  props: {
    teilnehmer: [],
    permanenttooltip: Boolean
  },
  data() {
    return {
      map: null,
      zoom: 17,
      ortmarker: require('./../assets/icons/map-pin-bordered.svg'),
      ortsign: require('./../assets/map-sign.svg'),
      center: L.latLng(this.teilnehmer[0]?.latitude, this.teilnehmer[0]?.longitude),
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  computed: {},
  watch: {
    'teilnehmer'() {
      this.$nextTick(() => {
        if (this.teilnehmer.length) {
          this.bounds()
        }
      })
    },
  },
  mounted() {
  },
  methods: {
    bounds: function () {
      //console.log(this.$refs.features.mapObject.getBounds())

      this.$refs.map.mapObject.fitBounds(this.$refs.features.mapObject.getBounds().pad(0.07))
    },
    goto: function (waypointid, hallenid) {
      if (this.$root.displaymode === '2d') {
        this.$router.push({name: 'Stand2d', params: {stand: waypointid}})
      } else {
        this.$router.push({name: 'Hall', params: {id: hallenid, stand: waypointid}})
      }
    },
  },
}
</script>

<style scoped lang="scss">
.karte {
  height: 500px;
  margin: 0;

  @media screen and (max-width: 768px) {
    height: 300px;
  }


}

</style>
