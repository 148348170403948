<template>
  <li
    style="position:relative;background-color: white;padding: 10px; margin-bottom: 5px; display: block;cursor: pointer"
    @click="goto(firma.id, firma.Halle)"
  >
    <div style="background-color: white;padding: 10px; padding-right:0;padding-top:0;float: right">
      <img :src="firma.Logo" :alt="firma.Name" style="width: 70px;">
    </div>
    <h2 style="margin:0;font-weight:500;color: #444;font-size: 14px;padding: 0px;hyphens: auto;">{{ firma.Firma }}</h2>
    <p style="margin:0;color: #aaa;font-size: 12px;padding-top: 5px;hyphens: auto" v-html="berufelistehightlighted"></p>
    <div style="clear: both"></div>
    <button v-if="this.$root.displaymode !== '2d'"
            style="
            position:absolute;right: 0;bottom:0;padding: 5px 10px; background-color: #00A650;
            color: white; border: none;border-top-left-radius: 5px;
            font-size: 12px; font-weight: 400; cursor: pointer; "
    >Stand in der Messehalle besuchen
    </button>
    <button v-if="this.$root.displaymode === '2d'"
            style="
            position:absolute;right: 0;bottom:0;padding: 5px 10px; background-color: #00A650;
            color: white; border: none;border-top-left-radius: 5px;
            font-size: 12px; font-weight: 400; cursor: pointer; "
    >Firmenprofil ansehen
    </button>
  </li>
</template>

<script>
import escapeRegExp from 'lodash/escapeRegExp'

export default {
  name: 'ausstellerresult',
  components: {},
  props: {
    firma: Object,
    gesuchterberuf: String
  },
  data() {
    return {}
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
    berufeliste: function () {
      if (this.firma.berufe.length) {
        // implode all berufe in object with key Beruf in one string
        return this.firma.berufe.map(beruf => beruf.Beruf).join(', ')
      }

      return ''
    },
    berufelistehightlighted() {
      //hightlight gesuchterberuf in berufeliste
      if (this.gesuchterberuf) {
        let search = this.gesuchterberuf
        search = escapeRegExp(search)
        return this.berufeliste.replace(new RegExp(search, 'gi'),
          '<span style="background-color: #ffc107;color:#444">$&</span>')
      }
      return this.berufeliste

    }
  },

  methods: {
    hallcolor(firm) {
      let firma = firm

      let hallen = JSON.parse(JSON.stringify(this.$root.hallen))

      let valObj = hallen.filter(function (halle) {

        return (parseInt(halle.id) === parseInt(firma.Halle))
      })
      if (valObj[0] && valObj[0].mcolor)
        return valObj[0].mcolor

    },
    goto: function () {
      if (this.$root.displaymode === '2d') {
        this.$router.push({name: 'Stand2d', params: {id: this.firma.Halle, stand: this.firma.id}})
      } else {
        this.$router.push({name: 'Hall', params: {id: this.firma.Halle, stand: this.firma.id}})
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
