<template>
  <div class="item" ref="area">
    <div class="number">{{ currentZahl }}</div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'countup',
  props: {
    zahl: Number,
    text: String
  },
  data() {
    return {
      currentZahl: 0
    }
  },
  mounted() {
    let self = this
    const jadeScale = this.$refs.area;
    const options = {rootMargin: "-150px"};
    // https://www.mediaevent.de/javascript/intersection-observer.html
    const jadeScaleObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          self.startCountup();
        } else {
          // entry.target.classList.remove("image-scaling");
        }
      });
    }, options);

    jadeScaleObserver.observe(jadeScale);


  },
  methods: {
    startCountup() {
      // start countup with easing
      let self = this
      let zahl = this.zahl
      let step = 0
      let steps = 100
      let intervall = setInterval(function () {
        let newzahl = zahl * (step / steps)
        // console.log(newzahl)
        self.currentZahl = newzahl.toFixed(0)
        step++
        if (step === steps) {
          clearInterval(intervall)
          self.currentZahl = zahl
        }
      }, 20)
    }
  },
}
</script>

<style scoped lang="scss">

.container-3er {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  .item {
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 480px) {
      padding: 20px;
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 33.3333333333%;
    }

    .number {
      font-size: 8.8vw;
      line-height: 1.4;
    }
    .text {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}

</style>
